body {
  background-color: #F4F4FA;
  font-family: 'Open Sans', 'sans-serif';
}

.proposalCard {
  background-color: #FFFFFF !important;
}

.proposalCardSelect {
  box-shadow: 0 1px 1px #03A9F4, 0 0 8px #03A9F4 !important;
}

.proposalCondition {
  color: #4B4B4B;
  margin: 0px 8px 0px 0px;
  width: auto;
}

.proposalConditionContainer {
  margin-top: 40px !important;
}

.proposalConditionIcon {
  font-size: 32px !important;
}

.proposalConditionIconContainer {
  text-align: center;
}

.proposalGrid {
  cursor: pointer;
  padding: 4px;
  width: 100%;
  height: 100%;
}

.proposalPanelButtons {
  margin-top: 64px;
}

.proposalPanelContract {
  text-align: right;
}

.proposalTitle {
  margin: 16;
  text-align: center;
}

.proposalDescription {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.carousel-container {
  width: 100%;
}

.separated-plans-container {
  margin-top: 21px;
  margin-bottom: 96px;
  width: 1064px;
  position: relative;
}

.date-display {
  position: absolute;
  top: 0;
  right: 0;
  color: #4C1DB7;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.separated-plans-container h2 {
  margin-top: 0px;
}

.products-carousel {
  display: inline-flex;
  align-items: baseline;
  width: 100%;
  flex-direction: column;
}

.products-carousel > div:not(:last-child) {
  margin-right: 40px;
}

.valid-quotation {
  color: #4C1DB7;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15;
  font-weight: 600;
  display: block;
}

.products-tabs {
  display: none;
  width: 100%;
  margin-top: 32px;
}

.desktop-footer,
.mobile-footer {
  margin-top: 30px;
}

.mobile-footer {
  display: none;
}

.mobile-footer button {
  width: 100%;
  height: 54px;
  margin-top: 8px;
}

.title {
  color: #e38008;
  font-size: 34px;
  line-height: 36px;
  font-weight: 400;
}

@media (max-width: 992px) {

  .separated-plans-container {
    width: 100%;
    margin: 21px 20px 34px 20px;
  }

  .products-carousel {
    display: none;
  }

  .products-tabs {
    display: block;
  }

  .date-display {
    display: none;
  }

  .desktop-footer {
    display: none;
  }

  .mobile-footer {
    display: block;
  }

  .valid-quotation {
    font-size: 10px;
  }
}